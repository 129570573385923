html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.has-scroll-smooth {
  overflow: hidden;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.c-scrollbar {
  width: 6px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  transition: opacity .3s ease-in-out;
  opacity: 0;
  z-index: 5;
}

.c-scrollbar:hover {
  cursor: -webkit-grab;
  cursor: grab;
}

.c-scrollbar_thumb {
  background-color: var(--scrollbar-thumb);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.c-scrollbar_thumb:hover {
  cursor: pointer;
}

html.has-scroll-scrolling .c-scrollbar,
html.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  white-space: nowrap;
  height: 100vh;
  display: inline-block;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100vw;
  height: 4px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}