html,
body {
  margin: 0;
}

html {
  -webkit-text-size-adjust: none;
}

html::-webkit-scrollbar {
  width: 0;
  background-color: "transparent";
}

body {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  -ms-scroll-chaining: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

button {
  background: none;
  border: 0;
  outline: none;
}

dialog {
  border: none;
}

p {
  margin-top: 0;
}

ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

#webgl {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#page {
  display: none;
}

#root {
  position: relative;
  z-index: 5;
  width: 100%;
}

.content-root {
  position: relative;
  z-index: 10;
}

.route-container {
  position: relative;
}

.route-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

/* REMOVE RECAPTCHA BADGE */
.grecaptcha-badge {
  visibility: collapse !important;
}

/* ACCESSIBILITY KEYBOARD NAVIGATION */
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  outline: -webkit-focus-ring-color auto 5px;
}

.js-focus-visible input.focus-visible,
.js-focus-visible textarea.focus-visible {
  outline: none;
}

/* Solve "Unable to preventDefault inside passive event listener invocation." Error */
.slick-list,
.slick-track {
  touch-action: none;
}

/* Hide IE banner by default */
.ie-notice {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  width: 600px;
  height: 200px;
  position: absolute;
  z-index: 1;
  top: calc(50% - 100px);
  left: calc(50% - 300px);
  background-color: black;
  color: white;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: none;
}

.ie-notice .container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.ie-notice .container p {
  width: 100%;
}

.ie-notice a {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: white;
  margin-top: 10px;
}

/* Show IE banner on IE10/11 */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .ie-notice {
    display: flex;
  }
}
