@font-face {
  font-family: "CutiveMono";
  src: url("../fonts/CutiveMono-Regular.woff") format("woff"),
    url("../fonts/CutiveMono-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AcuminPro";
  src: url("../fonts/AcuminPro-Regular.woff") format("woff"),
    url("../fonts/AcuminPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}